// ? IMPORTS
@import "form.scss";
@import "over.scss";
@import "tips.scss";

/* CORE = BUTTONS */
@mixin core-butt {
	background-color: transparent;
	border: none;
	color: var(--tone-core-text);
	cursor: pointer;
	padding: 0;
}
.core-butt {
	@include core-butt;
}

.core-butt-round {
	@include core-butt;
	padding: 12px;
	border-radius: 6px;
	color: var(--tone-core-body);
	background: var(--tone-core-text);
}

.core-butt-icon {
	@include core-butt;
	// width: 36px;
	// height: 36px;
	//padding:6px;
}

.core-butt-icon-circ {
	@include core-butt;
	background-color: var(--tone-core-text);
	border-radius: 50%;
	color: var(--tone-core-back);
	width: 32px;
	padding: 0;
	height: 32px;
}

// .core-butt-icon-circ span {
// 	transform:translate(-50%);
// }

/* CORE = ICONS */
$icons: (
	// USER INTERFACE
	actn: ('add', 'cut', 'copy', 'paste', 'delete', 'done', 'cancel', 'open', 'clear', 'close', 'save', 'prev', 'next', 'less', 'more', 'redo', 'undo', 'rename'),
	func: ('account',  'apps', 'bugs', 'danger', 'favourite', 'handle', 'help', 'lock', 'logout', 'mail', 'menu', 'options', 'question', 'settings', 'security', 'star'),
	link: ('discord', 'patreon', 'youtube', 'twitter'),
	menu: ('actr', 'plce', 'item', 'evnt', 'scpt', 'open', 'open-filled', 'file', 'page'),
	sort: ('alpha', 'created', 'modified', 'forward', 'backward', 'upward', 'downward'),
	stat: ('book', 'cloud', 'cloud-ready', 'cloud-queue', 'cloud-done', 'visibility', 'visibility-off'),
	tool: ('edit', 'mood', 'roll', 'icon', 'pico', 'luna', 'sola', 'open', 'open-filled'),
	// EDITOR FUNCTIONS
	node: ('paragraph', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'list-bulleted', 'list-check', 'list-numbered', 'table'),
	mark: ('bold', 'italic', 'underline', 'strike', 'indent-decrease', 'indent-increase', 'align-left', 'align-center', 'align-right', 'align-justify', 'superscript', 'subscript', 'clear'),
	extn: ('attach', 'comment', 'file', 'image', 'mention', 'search', 'spellcheck', 'tag', 'text', 'time'),
	work: ('col', 'row', 'pop', 'more'),
);
// $menus: ('actr', 'plce', 'item', 'evnt', 'scpt');

$sizes: (
	xs: 14px,
	sm: 20px,
	md: 26px,
	lg: 32px,
	xl: 38px,
);

$tones: (
	body: var(--tone-core-body),
	back: var(--tone-core-back),
	fore: var(--tone-core-fore),
	over: var(--tone-core-over),
	text: var(--tone-core-text),
	
	actr: var(--tone-actr-fore),
	plce: var(--tone-plce-fore),
	item: var(--tone-item-fore),
	evnt: var(--tone-evnt-fore),
	scpt: var(--tone-scpt-fore),
);

@mixin icon($type, $icon) {
	mask-image: url(/assets/icon/#{$type}-#{$icon}.svg);
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: contain;

	-webkit-mask-position: center;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-size: contain;
}

.core-icon {
	margin:3px;
	background-color: var(--tone-core-text);

	// ? ATTR = DATA-ICON
	@each $type, $list in $icons {
		@each $icon in $list {
			&[data-icon="#{$type}-#{$icon}"] {
				@include icon($type, $icon);
			}
		}
	}
	// ? ATTR = DATA-SIZE
	@each $size, $valu in $sizes {
		&[data-size="#{$size}"] {
			height: $valu;
			width: $valu;
		}
	}
	// ? ATTR = DATA-TONE
	@each $tone, $valu in $tones {
		&[data-tone="#{$tone}"] {
			background-color: $valu;
		}
	}
}
