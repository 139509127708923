// ? MIXINS
@mixin core-form {}

// ? CLASSES
.core-form-field {
	display: block;
	border-radius: 6px;
	border: 1px solid var(--tone-core-text);
	padding: 0 12px 12px 12px;
	box-sizing: border-box;
	opacity:0.6;

	&:focus-within {
		opacity:1;
	}

	&:has(.ng-valid) {
		opacity:1;
	}

	// &:has(.ng-invalid) {
	// 	opacity:1;
	// 	border: 1px solid var(--tone-note-erra);
	// }

	label {
		position: relative;
		background-color: var(--tone-core-back);
		top: -10px;
		left: 0;
		padding: 0 4px 0 4px;
		color: var(--tone-core-text);
		font-size: small;
	}

	.core-form-input {
		display: flex;

		input {
			width: 100%;
			// padding: 5px;
			font-size: medium;
			background-color: transparent;
			border: none;
			outline: none;
			color: var(--tone-core-text);
			padding-left:0;
			padding-right:0;
			padding-top:0;
			padding-bottom:0;

			&:focus{
				outline: none;
				border:none;
				appearance:none;
				box-shadow: none;
			}
		}

		textarea {
			width: 100%;
			padding: 5px;
			font-size: medium;
			background-color: transparent;
			border: none;
			outline: none;
			color: var(--tone-core-text);
			resize: none;
			padding-left:0;
			padding-right:0;
			padding-top:0;
			padding-bottom:0;

			&:focus{
				outline: none;
				border:none;
				appearance:none;
				box-shadow: none;
			}
		}
	}
}