// ? MIXINS
@mixin core-tips {}

// ? CLASSES
.core-tips {
	position:		fixed;
	width:			200px;
	padding:		0;
	border-radius:	6px;
	color:			var(--tone-core-text);
	border:1px solid var(--tone-core-text);

	background-color:		var(--tone-core-fore);
	box-shadow:				0 0 3px 3px rgba(0, 0, 0, 0.3);

	.core-tips-menu {
		list-style:		none;
		width:100%;
		padding:3px 0 3px 0;
		margin:0;

		li {
			display:flex;
			padding:3px;
			margin:0;

			&.disabled {
				pointer-events:		none;
				opacity:			0.6;
			}

			&:hover {
				background-color:	var(--tone-core-over);
				cursor:				pointer;
			}

			.core-tips-menu-text {
				padding:4px;
				flex-grow: 8;
			}

			.core-tips-menu-drop {
				float:right;
			}
		}
	}

	.core-tips-menu-smol {
		display:flex;
		// float:left;
		width:100%;
		list-style:		none;
		padding-left:3px;
		margin:0;
		border-bottom: 1px solid var(--tone-core-text);

		li {
			padding:6px;

			&:hover {
				background-color:	var(--tone-core-over);
				cursor:				pointer;
			}
		}
	}
}
