// ? MIXINS
@mixin core-over {}

// ? CLASSES
.core-over {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index:10000;

    .core-over-back {
        position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.6);
    }

    .core-over-fore {
		position: fixed;
		height: auto;
		top:50%;
		left:50%;
		transform: translate(-50%, -50%);
		border-radius: 6px;
		background: var(--tone-core-back);
		box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);
		z-index: 1000;
	}
}