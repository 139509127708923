// ? VARIABLES
$menus: (
	actr: var(--tone-actr-fore),
	plce: var(--tone-plce-fore),
	item: var(--tone-item-fore),
	evnt: var(--tone-evnt-fore),
	scpt: var(--tone-scpt-fore),
);

/* MIXINS */
@mixin core-butt {
	background-color: transparent;
	border: none;
	color: var(--tone-core-text);
	cursor: pointer;
	padding: 0;
}

/* STYLES */
.core-dock {
	display: none;
	position: fixed;
	align-items: center;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 600px;
	height: 3rem;
	// border-radius: 6px;
	// background-color: var(--tone-core-back);
	// box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);

	// ? ON ACTIVE STATE
	&.actv {
		display: flex;
	}

	// ? ON DISABLED STATE
	&:disabled, [disabled] {
		opacity: 0.5;
	}

	// ? FOR MENU TONES
	@each $menu, $tone in $tones {
		&.menu-#{$menu} {
			color: $tone;
		}
	}

	// ? BUTTON GROUP DIVIDER
	.line {
		height:100%;
		border-left: 1px solid var(--tone-core-over);
		margin: 0 5px;
	}

	// TODO this should be inferred inside the icon element to reduce repeated code!!!
	button {
		width: 30px;
		cursor: pointer;
		border: none;
		background-color: transparent;
		color: var(--tone-core-text);
		height: 32px;
		padding: 0;

		span {
			transform: scale(0.8);
		}

		.drop {
			display: none;
			position: absolute;
			float: right;
			margin-top: 6px;
			height: 32px;
			border-radius: 6px;
			background-color: var(--tone-core-back);
			box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);

			&.actv {
				display: flex;
			}
		}
	}

	// TODO this should be made into a seperate generic component and called properly
	.dock-over {
		position:absolute;
		width:300px;
		left:50%;
		height:160px;
		overflow-y: scroll;
		top:50px;
		transform:translateX(-50%);
		border-radius: 6px;
		background-color: var(--tone-core-back);
		box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);

		&:before {
			content: "";
			width: 0px;
			height: 0px;
			position: absolute;
			border-width: 0 16px 16px 16px;
			border-color: transparent transparent var(--tone-core-back) transparent;
			border-style: solid;
			left: 142px;
			top: -16px;
		}

		.term {
			list-style:none;
			padding:0;
			margin:0;

			li {
				outline: none;
				width:100%;
		
				&:hover {
					background-color: var(--tone-core-fore);
				}
		
				button {
					width: 100%;
					cursor: pointer;
					border: none;
					background-color: transparent;
					color: var(--tone-core-text);
					height: 32px;
					padding: 6px;
					text-align: left;
			
					span {
						transform: scale(0.8);
					}
				}
			}
		}
	}
}
