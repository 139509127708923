// ? IMPORTS
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// @import './lib/modern-normalise.css';
@import './lib/overlay-scrollbars.css';
@import './lib/core/core.scss';
@import './lib/core/dock.scss';

// ? FONTS
@font-face {
    font-family:	'Open Sans';
	src:			local('Open Sans'), url('/assets/font/Open-Sans.ttf') format('truetype');
	font-weight:	400;
	font-style:		normal;
	font-display:	swap;
}

// ? VARIABLES
$luna-tone-core-body: #212121;
$luna-tone-core-back: #333333;
$luna-tone-core-fore: #444444;
$luna-tone-core-over: #555555;
$luna-tone-core-text: #F6F6F6;
$sola-tone-core-body: #BBBBBB;
$sola-tone-core-back: #CCCCCC;
$sola-tone-core-fore: #DDDDDD;
$sola-tone-core-over: #EEEEEE;
$sola-tone-core-text: #212121;
// * ACTR [ REDS ]
$luna-tone-actr-back: #AB0032;
$luna-tone-actr-fore: #CC0032;
$sola-tone-actr-back: #CC0032;
$sola-tone-actr-fore: #AB0032;
// * PLCE [ GREENS ]
$luna-tone-plce-back: #039E4B;
$luna-tone-plce-fore: #03C45D;
$sola-tone-plce-back: #03C45D;
$sola-tone-plce-fore: #039E4B;
// * ITEM [ BLUES ]
$luna-tone-item-back: #034ABC;
$luna-tone-item-fore: #0388E8;
$sola-tone-item-back: #0388E8;
$sola-tone-item-fore: #034ABC;
// * EVNT [ PURPLES ]
$luna-tone-evnt-back: #79018C;
$luna-tone-evnt-fore: #AC01C6;
$sola-tone-evnt-back: #AC01C6;
$sola-tone-evnt-fore: #79018C;
// * SCPT [ ORANGES ]
$luna-tone-scpt-back: #CE6808;
$luna-tone-scpt-fore: #FA7D09;
$sola-tone-scpt-back: #FA7D09;
$sola-tone-scpt-fore: #CE6808;
// * NOTE [ INDICATORS ]
$luna-tone-note-bugs: lightgreen;
$luna-tone-note-info: #3CF3CF;
$luna-tone-note-warn: yellow;
$luna-tone-note-erra: red;
$sola-tone-note-bugs: lightgreen;
$sola-tone-note-info: #3CF3CF;
$sola-tone-note-warn: yellow;
$sola-tone-note-erra: red;

@mixin luna() {
	--tone-core-body: #{$luna-tone-core-body};
	--tone-core-back: #{$luna-tone-core-back};
	--tone-core-fore: #{$luna-tone-core-fore};
	--tone-core-over: #{$luna-tone-core-over};
	--tone-core-text: #{$luna-tone-core-text};
	--tone-actr-back: #{$luna-tone-actr-back};
	--tone-actr-fore: #{$luna-tone-actr-fore};
	--tone-plce-back: #{$luna-tone-plce-back};
	--tone-plce-fore: #{$luna-tone-plce-fore};
	--tone-item-back: #{$luna-tone-item-back};
	--tone-item-fore: #{$luna-tone-item-fore};
	--tone-evnt-back: #{$luna-tone-evnt-back};
	--tone-evnt-fore: #{$luna-tone-evnt-fore};
	--tone-scpt-back: #{$luna-tone-scpt-back};
	--tone-scpt-fore: #{$luna-tone-scpt-fore};
	--tone-note-bugs: #{$luna-tone-note-bugs};
	--tone-note-info: #{$luna-tone-note-info};
	--tone-note-warn: #{$luna-tone-note-warn};
	--tone-note-erra: #{$luna-tone-note-erra};
};

@mixin sola() {
	--tone-core-body: #{$sola-tone-core-body};
	--tone-core-back: #{$sola-tone-core-back};
	--tone-core-fore: #{$sola-tone-core-fore};
	--tone-core-over: #{$sola-tone-core-over};
	--tone-core-text: #{$sola-tone-core-text};
	--tone-actr-back: #{$sola-tone-actr-back};
	--tone-actr-fore: #{$sola-tone-actr-fore};
	--tone-plce-back: #{$sola-tone-plce-back};
	--tone-plce-fore: #{$sola-tone-plce-fore};
	--tone-item-back: #{$sola-tone-item-back};
	--tone-item-fore: #{$sola-tone-item-fore};
	--tone-evnt-back: #{$sola-tone-evnt-back};
	--tone-evnt-fore: #{$sola-tone-evnt-fore};
	--tone-scpt-back: #{$sola-tone-scpt-back};
	--tone-scpt-fore: #{$sola-tone-scpt-fore};
	--tone-note-bugs: #{$sola-tone-note-bugs};
	--tone-note-info: #{$sola-tone-note-info};
	--tone-note-warn: #{$sola-tone-note-warn};
	--tone-note-erra: #{$sola-tone-note-erra};
};

// ? SCROLLBARS (WEBKIT BROWSERS)
// *::-webkit-scrollbar {
// 	width:			12px;
// 	height:			12px;
// }

// *::-webkit-scrollbar-thumb {
// 	//background-color: #F6F6F6;
// 	border-radius:	20px;
// 	border:			4px solid transparent;
// 	box-shadow:		inset 0 0 12px 12px #F6F6F6;
// }

*::-webkit-scrollbar {
	width:6px;
	height:6px;
	background:transparent;
}

*::-webkit-scrollbar-thumb {
	background: var(--tone-core-text);
}

*::-webkit-scrollbar-track {
	background:transparent;
}

*::-webkit-scrollbar-corner {
	background-color:	transparent;
}

// ? SCROLLBARS (FIREFOX BROWSERS)
* {
	scrollbar-color:var(--tone-core-text) transparent;
	scrollbar-width:6px;
}

html {
	width:			100%;
	height:			100%;
}

body {
	margin:			0;
	width:			100%;
	height:			100%;
	font-size:		11pt;
	font-family: 	"Open Sans", arial, sans-serif;
	color:			var(--tone-core-text);

	background-color:	var(--tone-core-body)!important;

	&.luna {
		@include luna();
	}

	&.sola {
		@include sola();
	}
}

// ? INPUT OVERRIDES
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*:focus{
    outline: none;
	border:none;
	appearance:none;
	box-shadow: none;
}
